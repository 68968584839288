export const en_widget = {
  // widget editor
  widget_heading: 'Widget Editor',
  sidebar_item1: 'Appearance Settings',
  item1_child1: 'Widget Editor',
  widget_companies: 'Companies',
  widget_save: 'Save',
  no_companies_msg:
    'Currently no companies are opened, click on “Search Companies” to change settings',
  collapse_item1: 'Integrate Widget',
  collapse_item1_tag:
    'Copy or install the widget code to publish your chat widget',
  collapse_item2: 'Appearance',
  collapse_item2_tag:
    'Choose from a list of chat avatars, colors or add your own',
  customize_mobile_Check: 'Customize Settings for Mobile',
  tab_desktop: 'Desktop',
  tab_mobile: 'Mobile',
  select_type: 'Select Widget Type',
  theme_color: 'Theme Color',
  chat_avatar: 'Chat Avatar',
  upload_image: 'Upload',
  enable_avatar_name: 'Customize Avatar Name',
  avatar_name: 'Avatar Name',
  avatar_name_tooltip:
    "Changing this name will replace the existing avatar's title.",
  chat_avatar_tooltip:
    'Selecting multiple avatars will result in a different image each time the chat widget is loaded on your website',
  custom_images: 'My Uploads',
  default_images: 'Default Images',
  widget_script: 'Widget Script',
  copy_snippet: 'Copy Snippet',
  widget_or: 'OR',
  easy_install: 'Easily Install on',
  wordpress_plugin: 'Wordpress via Plugin',
  send_developer: 'Send to Your Developer',
  designer_email: 'Designer Email',
  developer_emails: 'Developer Email(s)',
  send_instruction: 'Send Instructions',
  widget_error_info: 'Press enter to add email address.'
};
export default en_widget;
export { default as fr_widget } from '../fr-FR-WidgetDictionary/fr-Widget';
export { default as sp_widget } from '../sp-SP-WidgetDictionary/sp-Widget';
