import config from 'config';
import { getAppMetaAsync } from 'store/slices/auth/authSlice';
import storage from 'services/utils/storage';
import { getCookie, setCookie } from 'services/utils/generic.methods';

const themePalette = theme => ({
  '--primary': `var(--th-${theme})`,
  '--primary-1': `var(--th-${theme}-1)`,
  '--primary-2': `var(--th-${theme}-2)`,
  '--primary-3': `var(--th-${theme}-3)`,
  '--primary-4': `var(--th-${theme}-4)`,
  '--primary-5': `var(--th-${theme}-5)`,
  '--primary-6': theme === 'blazeo' ? '#ffffff' : `var(--th-${theme}-3)`,
  '--primary-light': `var(--th-light-${theme})`,
  '--primary-dark': `var(--th-dark-${theme})`,
  '--info-1': `var(--th-light-${theme})`,
  '--secondary-2': theme === 'blazeo' ? '#E96D47' : `var(--th-${theme}-5)`
});

export const changeThemeColor = theme => {
  if (theme) {
    let palette = themePalette(theme);
    for (let key in palette) {
      document.documentElement.style.setProperty(key, palette[key]);
    }
  }
};

const OnRollLogo =
  'https://apexchatproductionprofileimages.s3.us-east-2.amazonaws.com/Text_download.png0083105c-4aba-4c5e-bc22-459d79c7915e';
const ApexchatLogo =
  'https://apexchatproductionprofileimages.s3.us-east-2.amazonaws.com/Facebook_blazeo_actual.png2452c0d6-edd3-4213-b204-4a0b0c610f86';

export const AppMetaProvider = () => {
  const host = window.location.hostname;
  const _getHostUrl = host => {
    const _subDomainLength = host.split('.')[0].length;
    return host.substring(_subDomainLength + 1);
  };
  const _hostDomainSubString = _getHostUrl(host);
  // let apexchatOwnerCompanyKeys = config.apexchatOwnerCompanyKeys.split(',');
  let _hostDomain = host === 'localhost' ? 'blazeo.com' : _hostDomainSubString;
  // let isApexHostName = config.apexchatURLS.split(',').includes(_hostDomain);
  let isDirectHost = getCookie('DynamicMeta')
    ? JSON.parse(getCookie('DynamicMeta'))?.isDirectHost
    : true;
  let isReferrerHost = getCookie('DynamicMeta')
    ? JSON.parse(getCookie('DynamicMeta'))?.isReferrerHost
    : true;
  let _hostKey = JSON.parse(storage.getItem('AppMetaInfo'))?.['host'];

  const _staticMeta = function () {
    let staticMetaData = {
      isWhiteLabeled: true,
      androidAppLink:
        'https://play.google.com/store/apps/details?id=com.onroll.live',
      IOSAppLink: 'https://apps.apple.com/pk/app/onroll/id1554277859',
      whatsNewIframeLink: config.onRollIframeUrl
    };
    if (isDirectHost || isReferrerHost) {
      staticMetaData.isWhiteLabeled = false;
      staticMetaData.androidAppLink =
        'https://play.google.com/store/apps/details?id=com.apexchat.live';
      staticMetaData.IOSAppLink =
        'https://apps.apple.com/pk/app/apexconnect/id1385172978';
      staticMetaData.whatsNewIframeLink = config.apexchatIframeUrl;
    }
    return staticMetaData;
  };

  let _defaultValues = {
    hostName: 'Portal',
    signupUrl: null,
    allowSignup: false,
    hostLogo: OnRollLogo,
    haveMultipleOccurrences: true,
    isDefaultHost: true,
    isWhiteLabeled: true,
    ssoEnabled: false,
    hasCustomLogoUploaded: false,
    isDirectHost: false,
    favIconLogo: OnRollLogo
  };

  let _defaultApexChatValues = {
    hostName: 'Blazeo',
    signupUrl: null,
    allowSignup: false,
    hostLogo: ApexchatLogo,
    haveMultipleOccurrences: true,
    isDefaultHost: true,
    isWhiteLabeled: false,
    ssoEnabled: false,
    hasCustomLogoUploaded: false,
    isDirectHost: false,
    favIconLogo: ApexchatLogo
  };

  const _getCurrentTheme = () => {
    if (isDirectHost || isReferrerHost) {
      return 'blazeo';
    }
  };

  const _dynamicMeta = async function (cookie) {
    // Get from Cookie otherwise from MetaAPI
    let dynamicMeta = getCookie('DynamicMeta')
      ? JSON.parse(getCookie('DynamicMeta'))
      : null;
    if (cookie && dynamicMeta) {
      changeThemeColor(AppMetaProvider().GetCurrentTheme());
      return dynamicMeta;
    } else {
      const response = await getAppMetaAsync({
        data: {
          hostDomain: _hostDomain,
          hostKey: _hostKey ? _hostKey : ''
        }
      });
      let payload = {
        ..._defaultValues
      };
      if (response?.data?.data) {
        const { data } = response.data;
        if (
          (data.isDirectHost || data.isReferrerHost) &&
          data.haveMultipleOccurrences
        ) {
          payload = { ...data, ..._defaultApexChatValues };
        } else if (!data.isDefaultHost) {
          if (!data.hostLogo) {
            data.hostLogo =
              data.isDirectHost || data.isReferrerHost
                ? ApexchatLogo
                : OnRollLogo;
            data.favIconLogo = data.hostLogo;
          }
          payload = { ...data, isWhiteLabeled: false };
        } else if (
          !data.isDirectHost &&
          !data.isReferrerHost &&
          data.haveMultipleOccurrences
        ) {
          payload = { ...data, isWhiteLabeled: false };
        }
        isDirectHost = !!payload.isDirectHost;
        isReferrerHost = !!payload.isReferrerHost;
        // Dispatch to Redux and Save to Cookie
        setCookie(
          'DynamicMeta',
          JSON.stringify(payload),
          config.authCookieTimeoutInDays
        );
        changeThemeColor(AppMetaProvider().GetCurrentTheme());
      }
      return payload;
    }
  };

  return {
    GetStaticMeta: _staticMeta,
    GetDynamicMeta: _dynamicMeta,
    GetCurrentTheme: _getCurrentTheme,
    GetHostUrl: _getHostUrl
  };
};
