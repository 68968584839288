import React, { useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import routes from 'routes';
import generalRoutes from 'routes/generalRoutes';
import HandlePageRoutes from 'routes/HandlePageRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoading } from 'store/slices/loading/loadingSlice';
import Sidebar from 'pub-components/Sidebar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './styles.scss';
// import Home from 'pages/portal/home';
// import HomeRedirector from 'pages/portal/home/home-redirector';
import { isMobile } from 'react-device-detect';
import NotificationComp from 'pub-components/notification/notification';
import { selectRoles, setRolesToState } from 'store/slices/roles/rolesSlice';
import {
  getCompaniesAppointmentStatusFlag,
  getCompanyById,
  getCompanyDynamicConfig
} from 'store/slices/company/companySlice';
import TopBar from 'pub-components/Topbar';
import { getNavbarStatus } from 'store/slices/sidebar-nav/sidebarNavSlice';
import { useEffect } from 'react';
import SmartAppBanner from 'pub-components/SmartAppBanner';
import storage from 'services/utils/storage';
import {
  selectLoggedinCompany,
  setLoggedInCompanyToState
} from 'store/slices/auth/authSlice';
import { Enums } from 'services/dropdown-enums';
import {
  AppMetaProvider,
  changeThemeColor
} from 'pub-components/Sidebar/helper';
import appIcon from 'assets/app-images/blazeo_icon.png';
import PageLoading from 'pub-components/page-loading';
import DeleteModal from 'pages/portal/modal/deleteModal';
import { errors, toastMsg } from 'services/utils/variables';
import { toast } from 'react-toastify';
import {
  getUserPreferenceSettings,
  getUserPreferenceVersion,
  getUserPreferences,
  updateUserPreferences
} from 'store/slices/userPreferenceSettings/userPreferenceSlice';
import { selectUser } from 'store/slices/users/userSlice';

const Home = React.lazy(() => import('pages/portal/home'));
const HomeRedirector = React.lazy(() => import('routes/home-redirector'));

const PortalLayout = props => {
  const loading = useSelector(selectLoading);
  const userPreferences = useSelector(getUserPreferences);
  const userPreferencesVersion = useSelector(getUserPreferenceVersion);
  const { roles } = useSelector(selectRoles);
  const { navbar } = useSelector(getNavbarStatus);
  const company = useSelector(selectLoggedinCompany);
  const [showSidebar, setShowSidebar] = useState(navbar);
  const [showLimitedAccessModal, setShowLimitedAccessModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [animatePage, setAnimatePage] = useState(true);
  const [downloadLink, setDownloadLink] = useState('');
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [btnText, setBtnText] = useState('Download');
  const [bannerText, setBannerText] = useState('Download the Blazeo App');
  const { androidAppLink, IOSAppLink, isWhiteLabeled } =
    AppMetaProvider().GetStaticMeta();
  window.originalTitle = window.document.title;
  const dispatch = useDispatch();

  const showAndroidBanner = async () => {
    if (!isWhiteLabeled) {
      //Only show banner for Apex users
      if (/Android/i.test(navigator.userAgent)) {
        console.log('getting related apps...');
        const relatedApps = await navigator.getInstalledRelatedApps();
        console.log(relatedApps);
        if (relatedApps.length > 0) {
          setShowBanner(true);
          setBtnText('OPEN');
          setBannerText('Use the Blazeo App');
          let host = window.location.hostname;
          setDownloadLink(
            `intent://#Intent;package=com.apexchat.live;scheme=http://${host};end;`
          );
        } else {
          setShowBanner(true);
          setBtnText('Download');
          setBannerText('Download Blazeo App');
          setDownloadLink(androidAppLink);
        }
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        setShowBanner(true);
        setBtnText('Download');
        setBannerText('Download the Blazeo App');
        setDownloadLink(IOSAppLink);
      }
    }
  };

  useEffect(() => {
    const profile = JSON.parse(storage.getItem('Profile'));
    if (userPreferencesVersion > 0) {
      let prefs = { ...userPreferences };
      // Remove filters keys
      Object.keys(prefs).forEach(categoryKey => {
        if (categoryKey !== 'analytics') {
          const { filters, ...rest } = prefs[categoryKey];
          prefs[categoryKey] = rest;
        }
      });
      let payload = {
        userId: profile?.UserID,
        preferenceSettings: JSON.stringify(prefs)
      };
      updateUserPreferences(payload);
    }
  }, [userPreferencesVersion]);

  useEffect(() => {
    /* UIUX-1905 
    Link to download app from store
    */
    showAndroidBanner();
    if (!company || !Object.keys(company).lenth) {
      const user = JSON.parse(storage.getItem('Profile'));
      const companyId = user?.CompanyID;
      if (companyId && !roles?.showLimitedAccessWarning) {
        getCompany(companyId);
      }
    }
  }, []);

  // Fade in components on route change
  useEffect(() => {
    if (props.location.pathname) {
      setAnimatePage(true);
      setTimeout(() => {
        setAnimatePage(false);
      }, 1000);
    }
  }, [props.location.pathname]);

  window.addEventListener('storage', function (event) {
    if (event.key == 'Profile' && event.newValue == null) {
      setShowSessionModal(true);
    }
  });

  const getCompany = async companyId => {
    try {
      let { data } = await getCompanyById(companyId);

      if (data?.data && Object.keys(data.data).length) {
        const company = data.data;
        let tempRoles = JSON.parse(JSON.stringify(roles));

        // TODO - UIUX-1908
        if (!tempRoles.isAdminCompany && !company.isTranscriptVisible) {
          tempRoles.report.reportTypes = tempRoles.report.reportTypes.filter(
            rt => rt.id !== Enums.ReportType.Transcripts
          );
        }

        tempRoles['report'].requestLeadChange = company.canRequestLeadChange;
        let { isAppointmentScheduler, operatorsEnabled } =
          await getCompanyConfig();
        tempRoles['isAppointmentScheduler'] = isAppointmentScheduler;
        tempRoles['operatorsEnabled'] = operatorsEnabled;

        dispatch(setLoggedInCompanyToState(company));
        dispatch(setRolesToState(tempRoles));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCompanyConfig = async () => {
    try {
      let { data } = await getCompanyDynamicConfig();
      if (data && data?.success) {
        return data?.data;
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  const limitedAccessModalHandler = () => {
    setShowLimitedAccessModal(!showLimitedAccessModal);
  };

  const eventHideSessionModal = () => {
    setShowSessionModal(false);
    window.location.href = window.location.origin + '/sso/logout';
  };

  return (
    <>
      <SmartAppBanner
        appName={'Blazeo'}
        appLink={downloadLink}
        icon={appIcon}
        text={bannerText}
        btnText={btnText}
        show={showBanner}
      />
      {loading.show ? (
        <ProgressBar className="global-loader" now={loading.loading} />
      ) : null}
      <TopBar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        limitedAccessModalHandler={limitedAccessModalHandler}
        showBanner={showBanner}
      />
      <div
        className={`main d-flex align-items-stretch ${
          isMobile ? 'mobile' : 'desktop'
        }-wrapper`}
      >
        <NotificationComp />
        <Sidebar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          showLimitedAccessModal={showLimitedAccessModal}
          limitedAccessModalHandler={limitedAccessModalHandler}
          showBanner={showBanner}
        />
        <div id="content">
          <div
            className="h-100 full-height-min rounded-inner print-version-wrapper main-content-container"
            //style={{ pageBreakAfter: 'always' }}
            style={
              showBanner ? { paddingTop: '130px' } : { paddingTop: '70px' }
            }
          >
            <React.Suspense fallback={<PageLoading />}>
              <div
                className={`h-100 print-innner-content ${
                  animatePage && 'show-comp'
                }`}
              >
                <Switch>
                  <Route
                    path={'/'}
                    component={HomeRedirector}
                    exact={true}
                    key={`home-redirector`}
                  />

                  {HandlePageRoutes([...routes, ...generalRoutes], 'portal')}
                  <Redirect to="/not-found" />
                </Switch>
              </div>
            </React.Suspense>
          </div>
        </div>
      </div>
      <DeleteModal
        eventHideModal={eventHideSessionModal}
        showModal={showSessionModal}
        title="Session Expired"
        infoText={errors.inValidAuthToken}
        cancelButtonTitle="Okay"
        submitBtn={false}
        backdrop="static"
      />
    </>
  );
};

export default PortalLayout;
