import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import { toolTipPlacement, truncateText } from 'services/utils/generic.methods';
import renderToolTip from '../renderToolTip';
import { OverlayTrigger } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import './styles.scss';

const SimpleLabelTextBox = ({
  id,
  heading,
  field,
  roles,
  roleBased,
  dataProperty,
  titleText,
  error,
  errorMessage,
  helperText,
  editable,
  readOnly,
  handleChange,
  multiline,
  staticField,
  dropdownEnum,
  dropdownFlag,
  disabled,
  handleBlur,
  nullString,
  type = 'text',
  placeholder = '',
  styles = {},
  endAdornment
}) => {
  const [value, setValue] = useState(dataProperty);

  const rolesToApply = ['isAdminCompany'];

  helperText = helperText || titleText;
  const handleOnChange = element => {
    setValue(element.target.value);
    handleChange(element, field, element.target.value);
  };
  useEffect(() => {
    setValue(dataProperty);
  }, [dataProperty]);

  let checkAccessiblePermissions = (isRoleBased, roles) => {
    let result = false;
    if (isRoleBased) {
      rolesToApply.forEach(val => {
        if (!!roles[val]) {
          result = true;
        } else {
          result = false;
        }
      });
      return result;
    } else {
      return true;
    }
  };
  return (
    <>
      {checkAccessiblePermissions(roleBased, roles) ? (
        <FormGroup key={field + '-field'}>
          <FormControl
            fullWidth
            variant="outlined"
            className={`${value || value === 0 ? 'has-value' : ''} ${
              error ? 'is-invalid' : ''
            } ${!editable ? 'has-diabled' : ''}`}
          >
            <TextField
              id={field}
              type={type}
              label={
                isMobile ? truncateText(heading, 22) : truncateText(heading, 50)
              }
              placeholder={placeholder}
              variant="outlined"
              className="lined with-label"
              value={
                dropdownFlag
                  ? dataProperty === null || dataProperty === 0 || !dataProperty
                    ? nullString || 'N/A'
                    : dropdownEnum
                        ?.filter(enumType => enumType.value === dataProperty)
                        .map(item => item.name)
                  : value || Number(value) === 0
                  ? value
                  : ''
              }
              onChange={handleOnChange}
              onBlur={handleBlur}
              readOnly={!editable || readOnly}
              disabled={!editable ? true : false}
              error={!!error}
              InputProps={{
                endAdornment:
                  endAdornment ||
                  (helperText && (
                    <InputAdornment position="end">
                      <OverlayTrigger
                        placement={toolTipPlacement}
                        overlay={renderToolTip(helperText)}
                      >
                        <UilInfoCircle className="list-icon-after-text" />
                      </OverlayTrigger>
                    </InputAdornment>
                  ))
              }}
            />
            {error && (
              <FormHelperText
                style={{ color: 'red', position: 'absolute', bottom: '-22px' }}
              >
                {error}
              </FormHelperText>
            )}
          </FormControl>
        </FormGroup>
      ) : null}
    </>
  );
};

export default SimpleLabelTextBox;
